import React from 'react';
import { Zaposlitev } from 'components/Zaposlitev';

export default () => (
  <Zaposlitev>
    <h1>Zaposlitev</h1>
    <br />
    <br />
    <h2>ZAPOSLIMO - PODATKOVNI ANALITIK/DATA SCIENTIST - M/Ž</h2>
    <p>
      V ekipi Enetra d.o.o. iščemo novega člana, ki bi se pridružil naši Data Science ekipi. Želimo si zagnanega in
      strastnega raziskovalca, ki nam bo pomagal vzdrževati in prilagajati že postavljen in delujoč sistem. Verjamemo v
      dobre ideje in smo jim pripravljeni prisluhniti vse dni v tednu :)
    </p>

    <p>Pri kandidatu si želimo:</p>
    <ul>
      <li>vsaj 3+ leta izkušenj na področju podatkovne znanosti in strojnega učenja (ML),</li>
      <li>zelo dobro poznavanje programskega jezika Python,</li>
      <li>znanje in izkušnje s pandas, scikit-learn in statsmodels,</li>
      <li>
        praktične izkušnje pri modeliranju in razvoju naprednih interaktivnih nadzornih plošč z uporabo BI orodij, kot
        je npr. Tableau
      </li>
      <li>
        diplomant/ka matematike/statistike, računalništva/energetike ali sorodnega področja; izkušnje iz podobnih
        področij so zaželene{' '}
      </li>
      <li>zelo dobra pisna in govorna tehnična angleščina</li>
    </ul>
    <p>Plus bi bil:</p>
    <ul>
      <li>dosedanje delo v elektroenergetskem sektorju,</li>
      <li>zelo dobro pisno in ustno komuniciranje v slovenskem jeziku,</li>
      <li>predstavitev objave lastne raziskave/prikaz lastne programske rešitve, ki prikazuje inovativne raziskave.</li>
    </ul>
    <p>Kaj ponujamo:</p>
    <ul>
      <li>konkurenčni plačni paket, ki je odvisen od vaših izkušenj (od 3000 bruto dalje),</li>
      <li>delo z odlično ekipo in nekaterimi najboljšimi posamezniki v elektroenergetskem sektorju v EU,</li>
      <li>proračun za učenje (konference in mentorji na voljo v Sloveniji in Evropi),</li>
      <li>možnost hibridnega dela ali dela na daljavo,</li>
      <li>izbira želene opreme,</li>
      <li>bonus za izjemno uspešnost.</li>
    </ul>
    <br />
    <br />
    <h3>Kako se prijaviš</h3>
    <p>
      Pošljite nam svoje najbolj impresivne projekte in nas prepričajte, da ste najboljši kandidat za to delovno mesto.
      Navdušite nas s svojimi inovativnimi pristopi in hobi projekti. Prijave pošljite na{' '}
      <a href="mailto:jobs@enetra.si">jobs@enetra.si</a>.
    </p>
    <p>Datum in kraj: Ljubljana, 1. 12. 2022</p>
  </Zaposlitev>
);
