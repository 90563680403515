import styled from 'styled-components';
import React from 'react';
import { Container, Layout, SEO } from './common';
import { Header } from './theme';
import { Wrapper } from './theme/Header/styles';

const Style = styled.div`
  padding: 30px 0;
  margin: 50px 0;

  p {
    line-height: 1.8rem;
  }
`;

export const Zaposlitev = ({ children }) => (
  <Layout>
    <SEO />
    <Wrapper>
      <Header nohome />
      <Container>
        <Style>{children}</Style>
      </Container>
    </Wrapper>
  </Layout>
);
